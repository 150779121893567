import React from 'react';
import { Typography } from '@/components/Typography';
import { cn } from '@/utils/utils';
import Link from 'next/link';

export const MarkdownLink: React.FC<{ href: string; text: React.ReactNode }> = ({ href, text }) => {
  let isInternal;
  try {
    new URL(href);
    isInternal = false;
  } catch {
    isInternal = true;
  }

  return (
    <Typography component={isInternal ? Link : 'a'} href={href} className={cn('underline')}>
      {text}
    </Typography>
  );
};
