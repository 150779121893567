import React from 'react';
import Link from 'next/link';
import { cn } from '@/utils/utils';
import { useRouter } from 'next/router';
import ChargingCable from '@/public/assets/icons/chargingCable.svg';
import Consumption from '@/public/assets/icons/consumption.svg';
import Receipt from '@/public/assets/icons/receipt.svg';
import User from '@/public/assets/icons/user.svg';
import Logout from '@/public/assets/icons/logout.svg';
import { Typography } from '@/components/Typography';
import { useTranslation } from 'next-i18next';
import Support from '@/public/assets/icons/support.svg';

interface NavigationEntryBase {
  text: string;
  icon: React.ReactNode;
}
interface NavigationEntryInternal extends NavigationEntryBase {
  path: string;
  href?: never;
}
interface NavigationEntryExternal extends NavigationEntryBase {
  path?: never;
  href: string;
}
export type NavigationEntries = Array<NavigationEntryInternal | NavigationEntryExternal>;

export const DesktopHeaderMenu: React.FC = () => {
  const { t } = useTranslation();
  const router = useRouter();

  const navigationEntries: NavigationEntries = [
    {
      text: t('global:navigation.charger'),
      path: '/my-page/charger',
      icon: <ChargingCable data-cy="chargerLinkIcon" className={cn('w-[32px]', 'shrink-0', '[&>path]:fill-gray-100')} />
    },
    {
      text: t('global:navigation.consumption'),
      path: '/my-page/consumption',
      icon: (
        <Consumption data-cy="consumptionLinkIcon" className={cn('w-[32px]', 'shrink-0', '[&>path]:fill-gray-100')} />
      )
    },
    {
      text: t('global:navigation.receipt'),
      path: '/my-page/receipts',
      icon: <Receipt data-cy="receiptLinkIcon" className={cn('w-[32px]', 'shrink-0', '[&>path]:fill-gray-100')} />
    },
    {
      text: t('global:navigation.profile'),
      path: '/my-page/profile',
      icon: <User data-cy="profileLinkIcon" className={cn('w-[32px]', 'shrink-0', '[&>path]:fill-gray-100')} />
    },
    {
      text: t('global:navigation.contact'),
      href: t('global:links.support'),
      icon: <Support data-cy="contactLinkIcon" className={cn('w-[32px]', 'shrink-0', '[&>path]:fill-gray-100')} />
    },
    {
      text: t('global:navigation.logout'),
      path: '/auth/logout',
      icon: <Logout data-cy="logoutLinkIcon" className={cn('w-[32px]', 'shrink-0', '[&>path]:fill-gray-100')} />
    }
  ];

  return (
    <nav>
      <ul className={cn('flex', 'gap-8', 'md:gap-6', 'text-gray-100')}>
        {navigationEntries.map(entry => {
          const isPath = router.pathname === entry.path;
          return (
            <li key={entry.path ?? entry.href}>
              <Typography
                type="S-H"
                component={entry.path ? Link : 'a'}
                href={entry.path ?? entry.href}
                className={cn(
                  'flex',
                  'gap-1',
                  'items-center',
                  'px-3',
                  'py-1',
                  'rounded-lg',
                  'hover:text-common-black',
                  '[&>svg>path]:hover:fill-common-black',
                  {
                    'text-common-black': isPath,
                    'bg-blue-50': isPath,
                    'hover:bg-gray-40': !isPath,
                    '[&>svg>path]:fill-common-black': isPath
                  },
                  'whitespace-nowrap'
                )}
              >
                {entry.icon}
                <Typography component="span" className={cn({ 'xl:hidden': !isPath })}>
                  {entry.text}
                </Typography>
              </Typography>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};
