const defaultPage = '/my-page/charger';
const loginPage = '/auth/login';
const logoutPage = '/auth/logout';
const basePath = '/v2';
const locales = ['nb', 'sv'];
const defaultLocale = 'nb';
const prodDomain = 'https://mobility.aneo.com';
const vercelProdDomain = 'https://portal-v2-nine.vercel.app';

module.exports = {
  defaultPage,
  loginPage,
  logoutPage,
  basePath,
  locales,
  defaultLocale,
  prodDomain,
  vercelProdDomain
};
