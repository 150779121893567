import React, { ErrorInfo } from 'react';
import { LoggingContextType, withLogger } from '@/utils/logger';
import { ErrorSplash } from '@/components/ErrorSplash';
import { useTranslation } from 'next-i18next';

interface Props {
  children?: React.ReactNode;
  logger: LoggingContextType;
}

interface State {
  hasError: boolean;
}

const ErrorBoundarySplash = () => {
  const { t } = useTranslation();

  return (
    <ErrorSplash
      header={t('global:errors.boundary.header')}
      body={t('global:errors.boundary.body')}
      buttonText={t('global:errors.boundary.button')}
    />
  );
};

class ErrorBoundaryComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    try {
      this.props.logger
        .addContext({
          error: error.message,
          cause: error.cause,
          stack: error.stack
        })
        .error('Application crashed');
      const previousSessionExists = sessionStorage.getItem('didFireCrashedApplicationHjEvent') !== null;
      if (!previousSessionExists && window.hj != undefined) {
        window.hj('event', 'Application crashed');
        sessionStorage.setItem('didFireCrashedApplicationHjEvent', 'true');
      }
    } catch {}
  }

  render() {
    if (this.state.hasError) {
      return <ErrorBoundarySplash />;
    }

    return this.props.children;
  }
}

const ErrorBoundary = withLogger(ErrorBoundaryComponent);

export { ErrorBoundary };
