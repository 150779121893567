const path = require('path');
const { datadogLogs } = require('@datadog/browser-logs');
const { locales, defaultLocale } = require('./vars');
const HttpBackend = require('i18next-http-backend/cjs');
const HMRPlugin = process.env.NODE_ENV === 'development' ? require('i18next-hmr/plugin').HMRPlugin : undefined;

const logMissingTranslation = (languages, namespace, key) => {
  try {
    const previousSessionExists = sessionStorage.getItem('didFireMissingTranslationHjEvent') !== null;
    if (!previousSessionExists && window.hj != undefined) {
      window.hj('event', 'Failed to load translations');
      sessionStorage.setItem('didFireMissingTranslationHjEvent', 'true');
    }
  } catch {}

  try {
    datadogLogs.logger.log(
      `Missing key "${key}" in namespace: "${namespace}" in languages: "${languages}"`,
      {
        MessageTemplate: 'Missing key "{key}" in namespace: "{namespace}" in languages: "{languages}"'
      },
      'error'
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
};

/** @type {import('next-i18next').UserConfig} */
module.exports = {
  i18n: {
    defaultLocale: defaultLocale,
    locales: locales,
    localeDetection: false
  },
  ...(typeof window !== 'undefined'
    ? {
        backend: {
          loadPath: '/locales/{{lng}}/{{ns}}.json'
        }
      }
    : {}),
  use:
    process.env.NODE_ENV === 'development'
      ? typeof window !== 'undefined'
        ? [HttpBackend, new HMRPlugin({ webpack: { client: true } })]
        : [new HMRPlugin({ webpack: { server: true } })]
      : [],
  serializeConfig: false,
  saveMissing: true,
  missingKeyHandler: logMissingTranslation,
  defaultNS: 'global',
  localePath: path.resolve('./public/locales')
};
