import { cn } from '@/utils/utils';
import React from 'react';
import { AlertType } from '@/components/Alert';

interface Props {
  children: React.ReactNode;
  type?: BannerType;
}

export enum BannerType {
  Alert = 'Alert',
  Warning = 'Warning',
  Info = 'Info',
  Success = 'Success'
}

export const Banner: React.FC<Props> = ({ children, type = BannerType.Alert }) => {
  return (
    <div
      className={cn(
        'min-h-12',
        'border-2',
        {
          'bg-system-red-20': type == BannerType.Alert,
          'border-system-red-100': type == BannerType.Alert,
          'bg-yellow-50': type == BannerType.Warning,
          'border-yellow-100': type == BannerType.Warning,
          'bg-blue-25': type == BannerType.Info,
          'border-blue-100': type == BannerType.Info,
          'bg-green-50': type == BannerType.Success,
          'border-green-100': type == BannerType.Success
        },
        'flex',
        'items-center'
      )}
    >
      <div
        className={cn(
          'flex-1',
          'mx-28',
          'px-2',
          'py-3',
          'flex',
          'flex-col',
          'items-start',
          'gap-3',
          'md:gap-1',
          'md:mx-0',
          'md:px-4',
          'md:justify-between'
        )}
      >
        {children}
      </div>
    </div>
  );
};
