import { UserToken, userAtom } from '@/state/user';
import {
  AuthenticateByOtpRequest,
  AuthenticationRequest,
  AuthenticationResponse,
  TokenInfo
} from '@/utils/api/services/openapi';
import usePrevious from '@/utils/hooks/usePrevious';
import { QueryClient } from '@tanstack/react-query';
import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios';
import { BroadcastChannel as BroadcastChannelLegacy } from 'broadcast-channel';
import { addMinutes, isAfter } from 'date-fns';
import { useAtom } from 'jotai';
import jwt_decode from 'jwt-decode';
import { useRouter } from 'next/router';
import { ParsedUrlQueryInput } from 'node:querystring';
import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { v4 } from 'uuid';
import { useLogger } from '@/utils/logger';

interface AuthContextType {
  login: (request: AuthenticationRequest) => Promise<void>;
  otpLogin: (request: AuthenticateByOtpRequest) => Promise<void>;
  logout: () => Promise<void>;
  refresh: () => Promise<void>;
  loginAs: (userId: string) => Promise<AuthenticationResponse | undefined>;
  returnToAdmin: () => Promise<AuthenticationResponse | undefined>;
}

const AuthContext = createContext<AuthContextType>({
  login: () => Promise.resolve(undefined),
  otpLogin: () => Promise.resolve(undefined),
  logout: () => Promise.resolve(undefined),
  refresh: () => Promise.resolve(undefined),
  loginAs: () => Promise.resolve(undefined),
  returnToAdmin: () => Promise.resolve(undefined)
});

const useAuth = () => useContext(AuthContext);

function withAuth<T>(Component: React.ComponentType<T & { auth: ReturnType<typeof useAuth> }>) {
  return function WrappedComponent(props: any) {
    const auth = useAuth();
    return <Component {...props} auth={auth} />;
  };
}

enum AuthState {
  LoggedOut,
  LoggingOut,
  LoggedIn,
  LoggingIn,
  Refreshing
}

const useAuthState = (queryClient?: QueryClient) => {
  const [_, setUser] = useAtom(userAtom);
  const [authState, setAuthState] = useState<AuthState>();
  const [resolvers, setResolvers] = useState<Array<(value: void | PromiseLike<void>) => void>>([]);

  const login = async (request: AuthenticationRequest) => {
    setAuthState(AuthState.LoggingIn);
    try {
      const response = await axios.post<AuthenticationResponse>('/api/login', request);
      const decodedToken = jwt_decode<UserToken>(response.data.accessToken!);
      const newUser: UserToken = {
        ...decodedToken,
        refreshExp: response.data.refreshTokenExpiresAt!
      };
      setUser(newUser);
      setAuthState(AuthState.LoggedIn);
    } catch (e) {
      setAuthState(AuthState.LoggedOut);
      throw e;
    }
  };

  const otpLogin = async (request: AuthenticateByOtpRequest) => {
    setAuthState(AuthState.LoggingIn);
    try {
      const response = await axios.post<AuthenticationResponse>('/api/otplogin', request);
      const decodedToken = jwt_decode<UserToken>(response.data.accessToken!);
      const newUser: UserToken = {
        ...decodedToken,
        refreshExp: response.data.refreshTokenExpiresAt!
      };
      setUser(newUser);
      setAuthState(AuthState.LoggedIn);
    } catch (e) {
      setAuthState(AuthState.LoggedOut);
      throw e;
    }
  };

  const logout = async () => {
    setAuthState(AuthState.LoggingOut);
    await axios.post('/api/logout');
    setUser(null);
    setAuthState(AuthState.LoggedOut);
  };

  const _refresh = async () => {
    try {
      const response = await axios.post<TokenInfo>('/api/refresh');
      const newUser = jwt_decode<UserToken>(response.data.accessToken!);
      setUser(user => ({
        ...user,
        ...newUser
      }));
      setAuthState(AuthState.LoggedIn);
    } catch {
      setAuthState(AuthState.LoggingOut);
      await logout();
    }
  };

  useEffect(() => {
    resolvers.forEach(resolver => resolver());

    if (authState === AuthState.Refreshing) {
      _refresh();
    }
  }, [authState]);

  const refresh = async () => {
    const promise = new Promise<void>((resolve, reject) => {
      setResolvers(state => [
        ...state,
        () => {
          if (authState === AuthState.LoggingOut) {
            reject();
          } else if (authState !== AuthState.Refreshing) {
            resolve();
          }
        }
      ]);
    });
    setAuthState(AuthState.Refreshing);
    return promise;
  };

  return { login, otpLogin, logout, refresh };
};

type AxiosConfig = InternalAxiosRequestConfig & {
  startTimestamp?: Date;
  retryCount?: number;
};

const AuthProvider: React.FC<{
  children: React.ReactNode;
  queryClient?: QueryClient;
}> = ({ children, queryClient }) => {
  const router = useRouter();
  const [user, setUser] = useAtom(userAtom);
  const prevUser = usePrevious<UserToken | undefined | null>(user);
  const broadcaster = useRef<BroadcastChannelLegacy>();
  const { login, otpLogin, logout, refresh } = useAuthState(queryClient);
  const logger = useLogger();

  const setupAxiosCorrelationInterceptor = () =>
    axios.interceptors.request.use(async (config: AxiosConfig) => {
      const correlationId = v4();
      config.headers['X-Correlation-Id'] = correlationId;
      config.startTimestamp = new Date();
      logger
        .addContext({
          CorrelationId: correlationId
        })
        .info('Start HTTP request "{Method}" "{Url}"', config?.method?.toUpperCase(), config?.url);
      return config;
    });

  const setupAxiosRequestInterceptor = () =>
    axios.interceptors.request.use(
      async config => {
        if (config.url === '/api/refresh' || config.url === '/api/logout') {
          return config;
        }

        const oneMinuteFromNow = addMinutes(new Date(), 1);
        if (user && !isAfter(new Date(user.exp * 1000), oneMinuteFromNow)) {
          await refresh();
        }

        return config;
      },
      error => Promise.reject(error)
    );

  const setupAxiosResponseInterceptor = () =>
    axios.interceptors.response.use(
      response => {
        const config: AxiosConfig | undefined = response.config;
        let delta;
        if (config?.startTimestamp) {
          delta = new Date().getTime() - config.startTimestamp.getTime();
        }
        logger
          .addContext({
            CorrelationId: response.headers['x-correlation-id']
          })
          .info(
            'End HTTP {Method} request {Url} after {ElapsedMilliseconds}ms - {StatusCode}',
            config?.method?.toUpperCase(),
            config?.url,
            delta,
            response.status
          );
        return response;
      },
      (error: AxiosError) => {
        const config: AxiosConfig | undefined = error.config;

        if (!config) {
          return Promise.reject(error);
        }

        if (config?.url !== '/api/refresh' && error.response?.status != 401) {
          let delta;
          if (config?.startTimestamp) {
            delta = new Date().getTime() - config.startTimestamp.getTime();
          }
          const loggerWithContext = logger.addContext({
            CorrelationId: error.response?.headers['x-correlation-id'],
            error: {
              stack: error.stack,
              message: error.message
            }
          });

          if (error.response?.status == undefined) {
            loggerWithContext.info(
              'HTTP {Method} network error {Url} after {ElapsedMilliseconds}ms',
              config?.method?.toUpperCase(),
              config?.url,
              delta
            );
          } else {
            loggerWithContext.warn(
              'HTTP {Method} error {Url} after {ElapsedMilliseconds}ms - {StatusCode}',
              config?.method?.toUpperCase(),
              config?.url,
              delta,
              error.response?.status
            );
          }

          return Promise.reject(error);
        }

        return Promise.reject(error);
      }
    );

  const loginAs = async (userId: string) => {
    const response = await axios.post<AuthenticationResponse>(`/api/admin/login-as?loginAsExternalId=${userId}`);
    const decodedToken = jwt_decode<UserToken>(response.data.accessToken!);
    setUser({
      ...decodedToken,
      refreshExp: response.data.refreshTokenExpiresAt!
    });
    logger.info('admin or customer service is impersonating user {UserId}', userId);
    return response.data;
  };

  const returnToAdmin = async () => {
    const response = await axios.post<AuthenticationResponse>('/api/admin/return-to-admin');
    const decodedToken = jwt_decode<UserToken>(response.data.accessToken!);
    setUser({
      ...decodedToken,
      refreshExp: response.data.refreshTokenExpiresAt!
    });
    logger.info('admin or customer service is returning to their own account');
    return response.data;
  };

  const checkAndDoRefresh = async () => {
    if (user && isAfter(new Date(), new Date(user?.refreshExp))) {
      await logout();
      return;
    }

    const oneMinuteFromNow = addMinutes(new Date(), 1);
    if (user && !isAfter(new Date(user.exp * 1000), oneMinuteFromNow)) {
      await refresh();
    }
  };

  const onWindowFocus = useCallback(async () => {
    let lastRefreshTime = parseInt(localStorage.getItem('lastRefresh') ?? `${Number.MAX_SAFE_INTEGER}`);

    if (Date.now() - lastRefreshTime > 1000) {
      await checkAndDoRefresh();
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  const onRouteChangeStart = useCallback(async () => {
    await checkAndDoRefresh();
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  const onWindowLoad = useCallback(() => {
    localStorage.setItem('lastRefresh', Date.now().toString());
  }, []);

  useEffect(() => {
    const broadcastChannel = new BroadcastChannelLegacy('refresh');
    broadcaster.current = broadcastChannel;
    broadcastChannel.onmessage = () => {
      router.reload();
    };

    return () => {
      broadcaster.current = undefined;
      broadcastChannel.close();
    };
  }, []);

  useEffect(() => {
    if (user == null) {
      return;
    }

    checkAndDoRefresh();

    router.events.on('routeChangeStart', onRouteChangeStart);
    window.addEventListener('focus', onWindowFocus);
    window.addEventListener('beforeunload', onWindowLoad);
    const requestCorrelationInterceptorId = setupAxiosCorrelationInterceptor();
    const requestInterceptorId = setupAxiosRequestInterceptor();
    const responseInterceptorId = setupAxiosResponseInterceptor();

    return () => {
      router.events.off('routeChangeStart', onRouteChangeStart);
      window.removeEventListener('focus', onWindowFocus);
      window.removeEventListener('beforeunload', onWindowLoad);
      axios.interceptors.request.eject(requestCorrelationInterceptorId);
      axios.interceptors.request.eject(requestInterceptorId);
      axios.interceptors.response.eject(responseInterceptorId);
    };
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const init = async () => {
      // We need to wait until we are no longer on /my-page as calling either cancelQueries or clear
      // while a query is still active will make it retry and those new retries have not been cancelled
      // causing some users to crash once they log back in if the query got a 401 or 403 error
      if (user === null && !router.pathname.startsWith('/my-page') && queryClient) {
        await queryClient.cancelQueries();
        queryClient.clear();
      }
    };
    init();
  }, [user, router.pathname]);

  useEffect(() => {
    if (user == null && (router.pathname.startsWith('/my-page') || router.pathname === '/auth/logout')) {
      broadcaster.current?.postMessage(null);
      let newUrl: { pathname: string; query?: ParsedUrlQueryInput } = { pathname: '/auth/login' };
      if (router.pathname !== '/auth/logout') {
        const fromParams = Object.entries(router.query).reduce((previousValue, currentValue, currentIndex) => {
          let newValue = previousValue + (currentIndex === 0 ? '?' : '&');
          newValue += `${currentValue[0]}=${currentValue[1]}`;
          return newValue;
        }, '');
        newUrl.query = { from: router.pathname, fromParams: encodeURIComponent(fromParams) };
      }
      router.push(newUrl);
    } else if (
      (prevUser === null && user !== null && router.pathname === '/auth/login') ||
      (user !== null && router.pathname === '/auth/otp' && user?.authmethod === 'Otp')
    ) {
      let newUrl: { pathname: string; query?: ParsedUrlQueryInput } = { pathname: '/my-page/charger' };
      if (router.query.from && typeof router.query.from === 'string' && router.query.from != '') {
        newUrl.pathname = router.query.from;
      }
      if (router.query.fromParams && typeof router.query.fromParams === 'string' && router.query.fromParams != '') {
        const urlSearchParams = new URLSearchParams(decodeURIComponent(router.query.fromParams));
        const newQuery: {
          [id: string]: string;
        } = {};
        urlSearchParams.forEach((value, key) => {
          newQuery[key] = value;
        });
        newUrl.query = newQuery;
      }
      router.push(newUrl);
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AuthContext.Provider value={{ login, otpLogin, logout, refresh, loginAs, returnToAdmin }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, useAuth, withAuth };
export type { AuthContextType };
