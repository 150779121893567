import { UserRole } from './constants/UserRole';

const roleIsAdministrator = (role: string): boolean => role === UserRole.Administrator;

const roleIsCustomerService = (role: string): boolean => role === UserRole.CustomerService;

const roleIsAdministratorOrCustomerService = (role: string): boolean =>
  roleIsAdministrator(role) || roleIsCustomerService(role);

export const isAdministrator = (role?: null | string | string[]): boolean =>
  role == null ? false : typeof role === 'string' ? roleIsAdministrator(role) : role.some(roleIsAdministrator);

export const isAdministratorOrCustomerService = (role?: null | string | string[]): boolean =>
  role == null
    ? false
    : typeof role === 'string'
    ? roleIsAdministratorOrCustomerService(role)
    : role.some(roleIsAdministratorOrCustomerService);

const roleIsCustomer = (role: string): boolean => role === UserRole.Customer;

export const isCustomer = (role?: null | string | string[]): boolean =>
  role == null ? false : typeof role === 'string' ? roleIsCustomer(role) : role.some(roleIsCustomer);
