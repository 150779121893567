import '@aneo-org/design-tokens/tokens.css';
import '@aneo-org/design-react/style.css';
import '@/styles/globals.css';
import '@/components/stepper/desktop/stepper.css';
import '@/components/subscriptionPackages/SubscriptionPackagePicker.css';
import '@/components/inputPhone/legacyInputPhone.css';
import '@/components/loader/loader.css';
import 'react-toastify/dist/ReactToastify.css';
import '@/utils/toast/toast.css';
import '@/components/inputDate/inputDate.css';
import '@/components/receipts/ReceiptEntry.css';
import '@/components/header/mobileHeaderMenu/mobileHeaderMenu.css';
import type { AppContext, AppInitialProps, AppProps } from 'next/app';
import NextApp from 'next/app';
import React, { useEffect } from 'react';
import { NextPage } from 'next';
import { OpenAPI } from '@/utils/api/services/openapi';
import { QueryClient } from '@tanstack/react-query';
import { Provider as StateProvider } from 'jotai';
import { ToastContainer } from '@/utils/toast/toast';
import { LoggerProvider } from '@/utils/logger';
import { ErrorBoundary } from '@/components/ErrorBoundary';
import { VERSION } from '@/datadogVersion';
import qs from 'querystring';
import jwt_decode from 'jwt-decode';
import { useHydrateAtoms } from 'jotai/react/utils';
import { userAtom, UserToken } from '@/state/user';
import { PiwikProvider } from '@/components/PiwikProvider';
import Script from 'next/script';
import TagManager from 'react-gtm-module';
import absoluteUrl from 'next-absolute-url';
import Head from 'next/head';
import { AuthProvider } from '@/utils/providers/AuthProvider';
import { DefaultGetLayout, GetLayout } from '@/layouts/DefaultLayout';
import { appWithTranslation } from 'next-i18next';
import nextI18NextConfig from '@/next-i18next.config';
import { basePath, vercelProdDomain, prodDomain } from '@/vars';
import { Announcements } from '@/components/Announcements';
import { ChargerConnection } from '@/utils/ChargerConnection';

type Page<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: GetLayout;
};

export type { Page };

type MyAppProps<P = {}> = AppProps<P> & {
  Component: Page<P>;
  user?: UserToken;
  apiPath: string;
};

const queryClient = new QueryClient();

const HydrateAtoms: React.FC<{ user?: UserToken }> = ({ user }) => {
  useHydrateAtoms(new Map([[userAtom, user]]));
  return null;
};

const title = 'Aneo Mobility';

function App({ Component, pageProps, user, apiPath, router }: MyAppProps): JSX.Element {
  const gtmId = process.env.NEXT_PUBLIC_GTM_ID;
  OpenAPI.BASE = apiPath;

  const getLayout = Component.getLayout ?? DefaultGetLayout;

  useEffect(() => {
    if (gtmId) TagManager.initialize({ gtmId });
  }, [gtmId]);

  return (
    <StateProvider>
      <LoggerProvider env={process.env.NEXT_PUBLIC_DATADOG_ENV} version={VERSION}>
        <ErrorBoundary>
          <HydrateAtoms user={user} />
          <AuthProvider queryClient={queryClient}>
            <PiwikProvider>
              <Head>
                <title>{title}</title>
                <meta charSet="utf-8" />
                <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="mobile-wep-app-capable" content="yes" />
                <meta name="apple-mobile-wep-app-capable" content="yes" />
                <meta name="apple-mobile-web-app-status-bar-style" content="default" />
                <link rel="icon" href={`${router.basePath}/favicon/icon.svg`} />
                <link rel="apple-touch-icon" sizes="180x180" href={`${router.basePath}/favicon/apple-touch-icon.png`} />
                <link rel="manifest" href={`${router.basePath}/favicon/manifest.webmanifest`} />
              </Head>
              <ChargerConnection queryClient={queryClient} />
              <Announcements queryClient={queryClient} />
              {getLayout(<Component {...pageProps} />, queryClient)}
              <ToastContainer />
              {(process.env.NODE_ENV == 'production' || process.env.NODE_ENV == 'test') && (
                <Script
                  src={
                    typeof window !== 'undefined'
                      ? `${window.location.origin}${router.basePath}/scripts/maze.js`
                      : '/scripts/maze.js'
                  }
                />
              )}
            </PiwikProvider>
          </AuthProvider>
        </ErrorBoundary>
      </LoggerProvider>
    </StateProvider>
  );
}

type MyAppInitialProps<P = {}> = AppInitialProps<P> & {
  user: UserToken | null;
  apiPath: string;
};

App.getInitialProps = async (context: AppContext): Promise<MyAppInitialProps> => {
  const { origin } = absoluteUrl(context.ctx.req);
  const maybeProdOrigin = origin == vercelProdDomain || origin == null ? prodDomain : origin;
  const cookies = qs.decode(context.ctx.req?.headers.cookie ?? '', '; ');
  const access_token = cookies['access_token'];
  const refresh_token_expires_at = cookies['refresh_token_expires_at'];
  let user = null;
  try {
    if (
      access_token &&
      typeof access_token == 'string' &&
      refresh_token_expires_at &&
      typeof refresh_token_expires_at == 'string'
    ) {
      user = jwt_decode<UserToken>(access_token);
      user.refreshExp = refresh_token_expires_at;
    }
  } catch {}

  const apiPath = `${maybeProdOrigin}${basePath}`;

  const ctx = await NextApp.getInitialProps(context);

  return { ...ctx, user: user, apiPath: apiPath };
};

export default appWithTranslation(App, nextI18NextConfig);
