import { AccessTokenResponse } from '@/pages/api/access-token';
import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { LoggingContextType, useLogger } from '@/utils/logger';
import {
  BroadcastChannel as BroadcastChannelLegacy,
  createLeaderElection,
  LeaderElector,
  OnMessageHandler
} from 'broadcast-channel';
import { ChargerStatus } from '@/utils/constants/ChargerStatus';
import { Charger } from '@/utils/api/services/openapi';
import { queryKey as chargerQueryKey } from '@/utils/hooks/useCharger';
import { QueryClient } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { userAtom } from '@/state/user';

const wsUrl = process.env.NEXT_PUBLIC_WS_URL ?? process.env.NEXT_PUBLIC_BASE_URL;

const accessTokenFactory = async (): Promise<string> =>
  (await axios.get<AccessTokenResponse>('/api/access-token')).data.accessToken;

export const BROADCASTER_NAMESPACE = 'chargerConnection';

export enum BroadcastType {
  NewLeader,
  Subscribe,
  Unsubscribe,
  event
}

interface BroadcastSubscribeOrUnsubscribePayload {
  chargerId: string;
  subscriberId: string;
}

interface BroadcastEventPayLoad {
  event: ChargerEvent;
}

export type BroadcastEvent =
  | {
      type: BroadcastType.Subscribe | BroadcastType.Unsubscribe;
      payload: BroadcastSubscribeOrUnsubscribePayload;
    }
  | {
      type: BroadcastType.event;
      payload: BroadcastEventPayLoad;
    }
  | {
      type: BroadcastType.NewLeader;
    };

enum HubMethod {
  ChargerEvent = 'ChargerEvent',
  SubscribeToCharger = 'SubscribeToCharger',
  UnsubscribeFromCharger = 'UnsubscribeFromCharger'
}

interface RawChargerEvent {
  chargerId: string;
  subject: string;
  body: string;
}

export type ChargerEvent = RawChargerEvent & {
  body: {
    timeStamp: Date;
    status?: string;
    action?: string;
    socketId?: number;
  };
};

const logChargerEvent = (logger: LoggingContextType, chargerEvent: ChargerEvent) => {
  if (chargerEvent.body.status === 'Info') {
    logger
      .addContext({ event: chargerEvent.body })
      .info('Got info event from charger "{ChargerId}"', chargerEvent.chargerId);
  } else if (
    chargerEvent.body.status != null &&
    chargerEvent.body.socketId != null &&
    Object.values<string>(ChargerStatus).includes(chargerEvent.body.status)
  ) {
    const loggerWithContext = logger.addContext({ event: chargerEvent.body });
    const loggingMessage =
      'Got status event from charger "{ChargerId}" with timestamp "{Timestamp}", new status "{Status}" on socket "{SocketId}"';
    const loggingArguments: [string, Date, string, number] = [
      chargerEvent.chargerId,
      chargerEvent.body.timeStamp,
      chargerEvent.body.status,
      chargerEvent.body.socketId
    ];
    if (chargerEvent.body.status === ChargerStatus.Error) {
      loggerWithContext.error(loggingMessage, ...loggingArguments);
    } else if (chargerEvent.body.status === ChargerStatus.Unavailable) {
      loggerWithContext.warn(loggingMessage, ...loggingArguments);
    } else {
      loggerWithContext.info(loggingMessage, ...loggingArguments);
    }
  } else {
    logger
      .addContext({ event: chargerEvent.body })
      .info('Got empty event from charger "{ChargerId}"', chargerEvent.chargerId);
  }
};

export const ChargerConnection: React.FC<{ queryClient: QueryClient }> = ({ queryClient }) => {
  const logger = useLogger();
  const user = useAtomValue(userAtom);
  const [connection, setConnection] = useState<HubConnection>();
  const [broadcaster, setBroadcaster] = useState<BroadcastChannelLegacy<BroadcastEvent>>();
  const [consumer, setConsumer] = useState<BroadcastChannelLegacy<BroadcastEvent>>();
  const [elector, setElector] = useState<LeaderElector>();
  const [subscriptions, setSubscriptions] = useState(
    new Map<string, { subscriberIds: Set<string>; handlerConnected: boolean }>()
  );

  /*
   * Request to start the connection if it isn't connected
   */
  const startConnectionIfNotConnected = useCallback(async () => {
    if (connection && connection.state === HubConnectionState.Disconnected) {
      try {
        logger.info('Is starting socket connection');
        await connection.start();
        logger.info('Started socket connection "{ConnectionId}"', connection.connectionId);
      } catch (e) {
        logger
          .addContext({
            error: (e as Error).message
          })
          .warn('Failed to start socket connection');
      }
    } else if (connection) {
      logger.info(
        'Tried to start socket connection, but the connection "{ConnectionId}" state was "{State}"',
        connection.connectionId,
        connection.state
      );
    }
  }, [connection]); // eslint-disable-line react-hooks/exhaustive-deps

  /*
   * Handler for broadcast event chargerEvent
   */
  const handleEvent = useCallback(
    (chargerEvent: ChargerEvent) => {
      if (chargerEvent.body.status == null) {
        return;
      }
      if (
        chargerEvent.body.socketId != null &&
        Object.values<string>(ChargerStatus).includes(chargerEvent.body.status)
      ) {
        queryClient.setQueryData<Charger>(chargerQueryKey(chargerEvent.chargerId), current =>
          current
            ? {
                ...current,
                sockets: current.sockets
                  ? current.sockets.map(socket => ({
                      ...socket,
                      status: socket.id === chargerEvent.body.socketId ? chargerEvent.body.status : socket.status
                    }))
                  : current.sockets
              }
            : current
        );
      }
      if (chargerEvent.body.status === 'Info') {
        queryClient.invalidateQueries(chargerQueryKey(chargerEvent.chargerId));
      }
    },
    [queryClient]
  );

  /*
   * Handler for broadcast event Subscribe
   */
  const handleSubscribe = useCallback(
    (subscriberId: string, chargerId: string) => {
      const subscriptionState = subscriptions.get(chargerId);
      const subscriberIds = new Set(subscriptionState?.subscriberIds);
      subscriberIds.add(subscriberId);
      const newState = {
        subscriberIds: subscriberIds,
        handlerConnected: subscriptionState?.handlerConnected ?? false
      };
      setSubscriptions(new Map(subscriptions.set(chargerId, newState)));
    },
    [subscriptions]
  );

  /*
   * Handler for broadcast event Unsubscribe
   */
  const handleUnsubscribe = useCallback(
    (subscriberId: string, chargerId: string) => {
      const subscriptionState = subscriptions.get(chargerId)!;
      if (subscriptionState === undefined || subscriptionState.subscriberIds.size === 0) {
        return;
      }
      const subscriberIds = new Set(subscriptionState?.subscriberIds);
      subscriberIds.delete(subscriberId);
      setSubscriptions(
        new Map(
          subscriptions.set(chargerId, {
            ...subscriptionState,
            subscriberIds: subscriberIds
          })
        )
      );
    },
    [subscriptions]
  );

  /*
   * Handler for broadcast events
   */
  const onMessage: OnMessageHandler<BroadcastEvent> = useCallback(
    async (ev: BroadcastEvent) => {
      switch (ev.type) {
        case BroadcastType.Subscribe: {
          if (!elector?.isLeader) {
            return;
          }

          handleSubscribe(ev.payload.subscriberId, ev.payload.chargerId);

          break;
        }
        case BroadcastType.Unsubscribe: {
          if (!elector?.isLeader) {
            return;
          }

          handleUnsubscribe(ev.payload.subscriberId, ev.payload.chargerId);

          break;
        }
        case BroadcastType.event: {
          handleEvent(ev.payload.event);

          break;
        }
      }
    },
    [elector, handleEvent, handleSubscribe, handleUnsubscribe]
  );

  /*
   * Every time the subscriptions update we need to unsubscribe if there's no subscribers left for the charger
   * or subscribe if there's one or more subscribers for a charger
   */
  useEffect(() => {
    if (!elector?.isLeader || connection == undefined) {
      return;
    }

    subscriptions.forEach(async (value, key) => {
      if (value.subscriberIds.size <= 0 && value.handlerConnected) {
        logger
          .addContext({
            connection: {
              state: connection.state,
              id: connection.connectionId
            }
          })
          .info('Unsubscribing from charger "{ChargerId}"', key);
        await connection.send(HubMethod.UnsubscribeFromCharger, key);
        subscriptions.delete(key);
      } else if (value.subscriberIds.size >= 1 && !value.handlerConnected) {
        if (connection.state !== HubConnectionState.Connected) {
          await startConnectionIfNotConnected();
        }
        logger
          .addContext({
            connection: {
              state: connection.state,
              id: connection.connectionId
            }
          })
          .info('Subscribing to charger "{ChargerId}"', key);
        subscriptions.set(key, {
          subscriberIds: value.subscriberIds,
          handlerConnected: true
        });
        await connection.send(HubMethod.SubscribeToCharger, key);
      }
    });
  }, [subscriptions]); // eslint-disable-line react-hooks/exhaustive-deps

  /*
   * Create a broadcaster and consumer (broadcaster) on startup
   * Close the broadcasters and clear state on destruction
   */
  useEffect(() => {
    const _broadcaster: BroadcastChannelLegacy<BroadcastEvent> = new BroadcastChannelLegacy(BROADCASTER_NAMESPACE);
    const _consumer: BroadcastChannelLegacy<BroadcastEvent> = new BroadcastChannelLegacy(BROADCASTER_NAMESPACE);
    setBroadcaster(_broadcaster);
    setConsumer(_consumer);

    return () => {
      setBroadcaster(undefined);
      setConsumer(undefined);
      _broadcaster.close();
      _consumer.close();
    };
  }, []);

  /*
   * Update the consumer's onMessage to our handler whenever the handler changes (or the consumer changes)
   */
  useEffect(() => {
    if (consumer == undefined) {
      return;
    }
    consumer.onmessage = onMessage;
  }, [consumer, onMessage]);

  /*
   * Whenever the broadcaster state is changed, create a leader election for the broadcaster
   * kill the elector on destruction
   */
  useEffect(() => {
    if (broadcaster == undefined) {
      return;
    }

    try {
      const elector = createLeaderElection(broadcaster);
      setElector(elector);

      return () => {
        elector.die();
      };
    } catch (e) {
      logger
        .addContext({
          error: e
        })
        .error('Broadcast channel "{BroadcastChannelId}" already have an elector', broadcaster.id);
    }
  }, [broadcaster]); // eslint-disable-line react-hooks/exhaustive-deps

  /*
   * Wait for elector to become leader when (if) the elector becomes the leader then start the connection and
   * subscribe to chargers
   */
  useEffect(() => {
    elector?.awaitLeadership().then(async () => {
      if (connection == undefined) {
        return;
      }

      elector?.broadcastChannel.postMessage({
        type: BroadcastType.NewLeader
      });

      // There's no reason to log the connection id or state is it hasn't been started yet as we lazily start
      logger.info('Setting up charger event handler for connection');
      connection?.on(HubMethod.ChargerEvent, (chargerEvent: RawChargerEvent) => {
        const body = JSON.parse(chargerEvent.body);
        const parsedEvent = { ...chargerEvent, body };
        logChargerEvent(logger, parsedEvent);
        elector?.broadcastChannel?.postMessage({
          type: BroadcastType.event,
          payload: {
            event: parsedEvent
          }
        });
      });
      connection?.onreconnected(() => {
        logger
          .addContext({
            connectionState: connection.state
          })
          .info('Reconnecting connection "{ConnectionId}"', connection.connectionId);
      });
    });
  }, [elector, connection]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      user == undefined &&
      connection?.state !== HubConnectionState.Disconnecting &&
      connection?.state !== HubConnectionState.Disconnected
    ) {
      connection?.stop();
    }
  }, [connection, user]);

  /*
   * Created connection
   */
  useEffect(() => {
    let _connection = new HubConnectionBuilder()
      .withUrl(`${wsUrl}/hubs/chargers`, { accessTokenFactory })
      .withAutomaticReconnect([0, 2000, 10000, 30000, 90000, 180000])
      .configureLogging(logger)
      .build();
    logger.info('Built socket connection');

    if (window.signalrMock) {
      _connection = window.signalrMock;
      logger.info('Is mocking socket connection');
    }

    setConnection(_connection);

    return () => {
      logger.info('Stopping connection "{ConnectionId}"', _connection?.connectionId);
      _connection?.stop();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};
