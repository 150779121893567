/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { HousingCompanyPricingsDto } from '../models/HousingCompanyPricingsDto';
import type { OneTimePricingsDto } from '../models/OneTimePricingsDto';
import type { ProductDTO } from '../models/ProductDTO';
import type { ProductPricingDTO } from '../models/ProductPricingDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PricingService {

    /**
     * @param parkingLotId
     * @param facilityId
     * @param discountCode
     * @returns OneTimePricingsDto Success
     * @throws ApiError
     */
    public static getOneTimePricings(
        parkingLotId?: string,
        facilityId?: string,
        discountCode?: string,
    ): CancelablePromise<OneTimePricingsDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/pricing/one-time-pricings',
            query: {
                'ParkingLotId': parkingLotId,
                'FacilityId': facilityId,
                'DiscountCode': discountCode,
            },
        });
    }

    /**
     * @param facilityId
     * @param discountCode
     * @returns ProductDTO Success
     * @throws ApiError
     */
    public static getFacilitySubscriptionPricings(
        facilityId?: string,
        discountCode?: string,
    ): CancelablePromise<Array<ProductDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/pricing/facility-subscription-pricings',
            query: {
                'FacilityId': facilityId,
                'DiscountCode': discountCode,
            },
        });
    }

    /**
     * @param facilityId
     * @param discountCode
     * @returns ProductPricingDTO Success
     * @throws ApiError
     */
    public static getFacilitySubscriptionPricingsV2(
        facilityId?: string,
        discountCode?: string,
    ): CancelablePromise<Array<ProductPricingDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/pricing/v2/facility-subscription-pricings',
            query: {
                'FacilityId': facilityId,
                'DiscountCode': discountCode,
            },
        });
    }

    /**
     * @param subscriptionPricingId
     * @returns ProductPricingDTO Success
     * @throws ApiError
     */
    public static getSubscriptionPricing(
        subscriptionPricingId: string,
    ): CancelablePromise<ProductPricingDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/pricing/subscription-pricing/{subscriptionPricingId}',
            path: {
                'subscriptionPricingId': subscriptionPricingId,
            },
        });
    }

    /**
     * @param pricingId
     * @returns ProductDTO Success
     * @throws ApiError
     */
    public static getPricingSubscriptionPricings(
        pricingId?: string,
    ): CancelablePromise<Array<ProductDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/pricing/pricing-subscription-pricings',
            query: {
                'PricingId': pricingId,
            },
        });
    }

    /**
     * @param housingCompanyId
     * @returns HousingCompanyPricingsDto Success
     * @throws ApiError
     */
    public static getHousingCompanyPricings(
        housingCompanyId: string,
    ): CancelablePromise<HousingCompanyPricingsDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/pricing/housing-company-pricings',
            query: {
                'housingCompanyId': housingCompanyId,
            },
        });
    }

}
