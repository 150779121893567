import React, { useEffect, useState } from 'react';
import { useAtomValue } from 'jotai';
import { userAtom } from '@/state/user';
import { useAnnouncements } from '@/utils/hooks/useAnnouncements';
import { Banner, BannerType } from '@/components/Banner';
import { useRouter } from 'next/router';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Typography } from '@/components/Typography';
import { AnnouncementDTO, AnnouncementType } from '@/utils/api/services/openapi';
import Markdown from 'react-markdown';
import { MarkdownLink } from '@/components/MarkdownLink';

const AnnouncementMarkdown: React.FC<{ children?: string | null }> = ({ children }) => {
  return (
    <Markdown
      components={{
        a(props) {
          return <MarkdownLink href={props.href ?? '#'} text={props.children as React.ReactNode} />;
        },
        p(props) {
          return (
            <Typography type="XS-B" component="span">
              {props.children as React.ReactNode}
            </Typography>
          );
        },
        em(props) {
          return (
            <Typography component="span" style="italic-reverse">
              {props.children as React.ReactNode}
            </Typography>
          );
        }
      }}
    >
      {children}
    </Markdown>
  );
};

const Announcements: React.FC = () => {
  const user = useAtomValue(userAtom);
  const { locale } = useRouter();
  const { data: announcements } = useAnnouncements({
    enabled: user !== undefined
  });

  if (announcements == undefined || announcements.length === 0) {
    return null;
  }

  const getBannerType = (type?: AnnouncementType) => {
    switch (type) {
      case AnnouncementType._0: {
        return BannerType.Alert;
      }
      case AnnouncementType._1: {
        return BannerType.Warning;
      }
      case AnnouncementType._2: {
        return BannerType.Info;
      }
      case AnnouncementType._3: {
        return BannerType.Success;
      }
      default: {
        return BannerType.Alert;
      }
    }
  };

  return (
    <>
      {announcements.map(a => (
        <Banner key={a.id} type={getBannerType(a.type)}>
          <AnnouncementMarkdown>
            {a.announcementTexts?.find(text => text.locale === locale)?.title}
          </AnnouncementMarkdown>
          <AnnouncementMarkdown>{a.announcementTexts?.find(text => text.locale === locale)?.body}</AnnouncementMarkdown>
        </Banner>
      ))}
    </>
  );
};

const QueryClientWrappedAnnouncements: React.FC<{ queryClient: QueryClient }> = ({ queryClient }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <Announcements />
    </QueryClientProvider>
  );
};

export { QueryClientWrappedAnnouncements as Announcements };
